import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <div className="p-6 space-y-6">
        <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
        <p className="text-sm text-gray-500">Last Updated: October 7, 2024</p>
        <p>
          Welcome to Mera Labs Technologies! This Privacy Policy outlines how we
          collect, use, and protect your information while using the Mera Chrome
          extension and related services ("Services"). By installing the Mera
          extension and using our Services, you agree to the practices described
          in this policy.
        </p>
        <h2 className="text-xl font-semibold mt-6">Information We Collect</h2>
        <p>
          We collect information to provide a better experience with our
          Services. This may include personal details you provide, usage data,
          and interactions with the Mera assistant. Refer to our full Privacy
          Policy at meralabs.io for details on data collected.
        </p>
        <h2 className="text-xl font-semibold mt-6">Data Usage and Storage</h2>
        <p>
          We use collected data to enhance functionality, improve user
          experience, and develop new features. Your data may be stored on
          secure servers with industry-standard safeguards to protect against
          unauthorized access.
        </p>
        <h2 className="text-xl font-semibold mt-6">
          License and Intellectual Property
        </h2>
        <p>
          Mera Labs Technologies grants you a limited, non-exclusive,
          non-transferable license to use the Services for personal,
          non-commercial purposes. All rights, title, and interest in the
          Services, including updates and new features, remain with Mera Labs
          Technologies.
        </p>
        <h2 className="text-xl font-semibold mt-6">User Responsibilities</h2>
        <ul className="list-disc pl-6 space-y-2">
          <li>
            Use Mera only for lawful purposes and according to this policy.
          </li>
          <li>
            Maintain the confidentiality of account credentials and notify us of
            any unauthorized access.
          </li>
          <li>
            You agree not to use Mera to:
            <ul className="list-disc pl-6">
              <li>Violate applicable laws or infringe upon others' rights.</li>
              <li>Transmit malicious code.</li>
              <li>Interfere with Mera's operation or networks.</li>
            </ul>
          </li>
        </ul>
        <h2 className="text-xl font-semibold mt-6">Data Protection</h2>
        <p>
          Your privacy is a priority. We employ industry-standard security
          measures to safeguard your data. Please refer to our Privacy Policy
          for details on data handling practices, including storage duration and
          deletion options.
        </p>
        <h2 className="text-xl font-semibold mt-6">
          Changes to This Privacy Policy
        </h2>
        <p>
          Mera Labs may modify this Privacy Policy as necessary. We encourage
          users to review this policy regularly. Changes are effective upon
          posting. We may also update specific terms or features without prior
          notice.
        </p>
        <h2 className="text-xl font-semibold mt-6">Governing Law</h2>
        <p>
          This Privacy Policy is governed by the laws of the State of Delaware,
          USA. Any legal action or proceeding shall be brought exclusively in
          the state or federal courts located in Delaware.
        </p>
        <h2 className="text-xl font-semibold mt-6">Contact Information</h2>
        <p>
          If you have questions or concerns regarding this Privacy Policy,
          please contact us at{" "}
          <a href="mailto:peacemaker@meralabs.io" className="text-blue-500">
            peacemaker@meralabs.io
          </a>
          .
        </p>
        <p className="font-semibold">
          Thank you for trusting Mera Labs Technologies with your data!
        </p>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
