import styled from "styled-components";

export const StyledWelcome = styled.section`
  width: 100%;
  min-height: 100vh;
  background: #1a1121;
  backdrop-filter: blur(2px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  .videoContainer {
    padding: 15px;
    width: 772px;
    height: 440px;
    border-radius: 20px;
    background: conic-gradient(
      from 180deg at 50% 50%,
      #d8c7ff 69.02772188186646deg,
      #ff86f9 133.75593781471252deg,
      #ffa6dd 181.79999828338623deg,
      #cf96ef 226.511070728302deg,
      #9c7fdb 286.13563299179077deg
    );
    iframe {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }
  button {
    margin: 43px 0 0 0;
    width: 184px;
    display: block;
    opacity: 1;
    transform: all 500ms ease-in-out;
    &.inactive {
      background: linear-gradient(
        141deg,
        rgba(147, 100, 245, 0.25) 0%,
        rgba(88, 26, 218, 0.25) 100%
      );
      opacity: 0.3;
      pointer-events: none;
    }
  }
  p {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.12px;
  }
  /* Media Queries */
  @media (min-width: 601px) and (max-width: 880px) {
    padding: 0 40px;
    .videoContainer {
      width: 100%;
      max-width: 772px;
      height: 300px;
    }
    button {
      margin: 30px 0 0 0;
    }
  }
`;
