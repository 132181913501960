import React from "react";
import { Link } from "react-router-dom";
import { StyledSmallFooter } from "./SmallFooter.styled";

const SmallFooter = () => {
  return (
    <StyledSmallFooter>
      <p>
        By proceeding you acknowledge that you have read, understood <br /> and
        agree to our{" "}
        <Link to="/termsofservice" target="_blank">
          Terms and Conditions
        </Link>{" "}
        &{" "}
        <Link to="/privacypolicy" target="_blank">
          Privacy Policy
        </Link>
        .
      </p>
      <span>
        @2024 Mera Labs Technologies{" "}
        <a
          href="https://join.slack.com/t/meralabs/shared_invite/zt-2uehy3p1t-VtZZHDJGHWZASAiEa4vuTg"
          target="_blank"
          rel="noreferrer"
        >
          Support
        </a>
      </span>
    </StyledSmallFooter>
  );
};

export default SmallFooter;
