import React from "react";
import { StyledAiCard } from "./AiCard.styled";

const AiCard = (props) => {
  const { item } = props;

  return (
    <StyledAiCard>
      <div className="aiCardInfo">
        <img src={item.icon} alt={item.title} />
        <h4>{item.title}</h4>
        <p>{item.description}</p>
      </div>
      <div className="aiCardVideo">
        <iframe
          src="https://www.youtube.com/embed/6LOtqprIeGQ"
          frameborder="0"
          allowfullscreen
          title="Get Started"
        ></iframe>
      </div>
    </StyledAiCard>
  );
};

export default AiCard;
