import styled from "styled-components";

export const StyledGetStarted = styled.section`
  width: 100%;
  min-height: 100vh;
  background: #1a1121;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  > a {
    text-decoration: none;
    .meraLogo {
      position: absolute;
      top: 48px;
      left: 48px;
      width: 108px;
      height: auto;
      display: block;
      object-fit: cover;
    }
  }
  .getStartedMain {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .getStartedInfo {
      display: flex;
      flex-direction: column;
      align-items: center;
      h2 {
        color: #fff;
        font-size: 90px;
        font-weight: 700;
        line-height: 1;
        letter-spacing: -1.44px;
      }
      p {
        margin: 16px 0 0 0;
        color: #706e87;
        font-size: 26px;
        font-weight: 400;
        line-height: 1;
      }
      button {
        margin: 60px 0 0 0;
        width: 600px;
        height: 56px;
        font-size: 29px;
        font-weight: 400;
      }
    }
  }
  /* Media Queries */
  @media (max-width: 600px) {
    display: none;
  }
  @media (min-width: 601px) and (max-width: 1050px) {
    .getStartedMain {
      img {
        max-height: 90%;
      }
      .getStartedInfo {
        h2 {
          font-size: 60px;
        }
        p {
          margin: 16px 0 0 0;
          font-size: 20px;
        }
        button {
          margin: 30px 0 0 0;
          width: 400px;
          font-size: 27px;
        }
      }
    }
  }
`;
