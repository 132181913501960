import styled from "styled-components";

export const StyledNavbar = styled.nav`
  width: 100%;
  height: 70px;
  background: #1a1121;
  display: flex;
  align-items: center;
  &.lightMode {
    background: #fff;
  }
  .navContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > a {
      img {
        width: 150px;
        height: auto;
        display: block;
        object-fit: cover;
      }
    }
    .navLinks {
      display: flex;
      align-items: center;
      gap: 15px;
      a {
        text-decoration: none;
        color: #969696;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.2;
      }
    }
    button {
      width: 200px;
      height: 48px;
      font-size: 16px;
      background: linear-gradient(141deg, #9364f5 0%, #581ada 100%);
      box-shadow: 0px 6px 24px 0px rgba(88, 26, 218, 0.45),
        0px 1px 9.3px 0px rgba(0, 0, 0, 0.25) inset,
        0px -4px 4px 0px rgba(0, 0, 0, 0.25) inset;
      transition: background 0.3s ease, transform 0.2s ease;
      img {
        width: 16px;
      }
      &:hover {
        background: linear-gradient(141deg, #a76ef5 0%, #6f1ada 100%);
        transform: scale(1.05);
        cursor: pointer;
      }
    }
  }
  /* Media Queries */
  @media (max-width: 600px) {
    .navContainer {
      > a {
        img {
          width: 120px;
        }
      }
      button {
        width: 180px;
        height: 40px;
        font-size: 14px;
        img {
          display: none;
        }
      }
    }
  }
`;
