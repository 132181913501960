import React from "react";
import ChromeIcon from "../../assets/images/icons/chrome.png";
import VoiceToTextIcon from "../../assets/images/icons/voiceToTextIcon.png";
import InBrowserIcon from "../../assets/images/icons/inBrowserIcon.png";
import QuickActionsIcon from "../../assets/images/icons/quickActionsIcon.png";
import CodingIcon from "../../assets/images/icons/codingIcon.png";
import Button from "../../components/Buttons/Button";
import Container from "../../components/Container/Container";
import AiCard from "../../components/AiCard/AiCard";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { StyledHome } from "./Home.styled";

const aiCardData = [
  {
    icon: VoiceToTextIcon,
    title: "Voice-to-Text",
    description:
      "Speak to type anywhere online, turning your voice into clean, ready-to-deliver text!",
  },
  {
    icon: InBrowserIcon,
    title: "In-Browser Sidebar",
    description:
      "Access Mera on every page to manage tasks and chat without disrupting your workflow.",
  },
  {
    icon: QuickActionsIcon,
    title: "Quick Actions",
    description:
      "Instantly access smart tools like CleanUp, Translate, and Summarize with a simple text highlight.",
  },
  {
    icon: CodingIcon,
    title: "Coding Assistant",
    description:
      "Input your thoughts & watch as they convert into structured, efficient code snippets.",
  },
];

function Home() {
  return (
    <>
      <Navbar lightTheme={false} />
      <StyledHome>
        <Container className="homeContainer">
          <div className="homeInfo">
            <h1>
              Your <span>Autonomous</span> <br /> Browser <span>Assistant</span>
            </h1>
            <p>
              The Simplest Way to Bring <br /> AI into Your Workflow
            </p>
            <Button
              text="Install Our Free Extension"
              imgSrc={ChromeIcon}
              handleClick={() =>
                window.open(
                  "https://chromewebstore.google.com/detail/mera-voice-first-browser/ekijmlblnngebdaeofaoabpnkeconbln",
                  "_blank"
                )
              }
            />
            <div className="videoContainer">
              <iframe
                src="https://www.youtube.com/embed/6LOtqprIeGQ"
                frameborder="0"
                allowfullscreen
                title="Get Started"
              ></iframe>
            </div>
          </div>
          <div className="homeAssistance">
            <div className="homeAssistanceInfo">
              <h2>
                Effortless AI Assistance, <br /> Seamlessly Integrated
              </h2>
              <p>
                Smart tools to simplify your workflow
                <br />
                —without interruptions.
              </p>
            </div>
            <div className="aiCardWrapper">
              {aiCardData.map((item, idx) => (
                <AiCard item={item} key={idx} />
              ))}
            </div>
            <div className="homeAssistanceMore">
              <span>and much more!</span>
            </div>
          </div>
        </Container>
      </StyledHome>
      <Footer lightTheme={false} />
    </>
  );
}

export default Home;
