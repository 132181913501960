import styled from "styled-components";

export const StyledHome = styled.section`
  padding: 50px 0;
  width: 100%;
  min-height: 100vh;
  background: #1a1121;
  .homeContainer {
    .homeInfo {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      h1 {
        color: #fff;
        text-shadow: 0px 4px 14px rgba(185, 153, 255, 0.25);
        font-size: 72px;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: -2.04px;
        span {
          background: radial-gradient(
            58.96% 45.59% at 50.05% 45.59%,
            #ed7dff 0%,
            #6c54ff 49%,
            #ed7dff 100%
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      p {
        margin: 24px 0 0 0;
        color: #969696;
        font-size: 18px;
        font-weight: 400;
        line-height: 1;
        br {
          display: none;
        }
      }
      button {
        margin: 38px 0 0 0;
        width: 279px;
        height: 52px;
        font-size: 18px;
      }
      .videoContainer {
        margin: 45px 0 74px 0;
        border-radius: 20px;
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%;
        &::before {
          content: "";
          position: absolute;
          inset: 0;
          width: 100%;
          height: 100%;
          background: conic-gradient(
            from 180deg at 50% 50%,
            #d8c7ff 69.02772188186646deg,
            #ff86f9 133.75593781471252deg,
            #ffa6dd 181.79999828338623deg,
            #cf96ef 226.511070728302deg,
            #9c7fdb 286.13563299179077deg
          );
        }
        iframe {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 98%;
          height: 96%;
          border: none;
        }
      }
    }
    .homeAssistance {
      display: flex;
      flex-direction: column;
      align-items: center;
      .homeAssistanceInfo {
        text-align: center;
        h2 {
          color: #fff;
          font-size: 30px;
          font-weight: 500;
          line-height: 1;
          letter-spacing: -1.08px;
          br {
            display: none;
          }
        }
        p {
          margin: 16px 0 0 0;
          color: #969696;
          font-size: 16px;
          font-weight: 400;
          line-height: 1;
          br {
            display: none;
          }
        }
      }
      .aiCardWrapper {
        width: 100%;
        margin: 30px 0 0 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 32px;
      }
      .homeAssistanceMore {
        margin: 25px 0 0 0;
        span {
          color: #b999ff;
          font-size: 16px;
          font-weight: 400;
          line-height: 1;
        }
      }
    }
  }
  /* Media Queries */
  @media (max-width: 600px) {
    .homeContainer {
      .homeInfo {
        h1 {
          font-size: 34px;
        }
        p {
          font-size: 17px;
          line-height: 1.4;
          br {
            display: block;
          }
        }
      }
      .homeAssistance {
        .homeAssistanceInfo {
          h2 {
            line-height: 1.4;
            br {
              display: block;
            }
          }
          p {
            margin: 16px 0 0 0;
            line-height: 1.4;
            br {
              display: block;
            }
          }
        }
      }
    }
  }
  @media (min-width: 601px) and (max-width: 1080px) {
    .homeContainer {
      .homeInfo {
        h1 {
          font-size: 60px;
        }
        p {
          font-size: 23px;
          line-height: 1;
        }
      }
      .homeAssistance {
        .homeAssistanceInfo {
          h2 {
            line-height: 1.4;
            font-size: 40px;
            br {
              display: block;
            }
          }
          p {
            margin: 16px 0 0 0;
            font-size: 23px;
            line-height: 1.4;
            br {
              display: block;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1080px) {
    padding: 50px 0;
    .homeContainer {
      .homeInfo {
        button {
          margin: 30px 0 0 0;
        }
        .videoContainer {
          margin: 45px 0 50px 0;
        }
      }
      .homeAssistance {
        .aiCardWrapper {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
  }
`;
