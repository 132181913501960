// Import the functions you need from the SDKs
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
} from "firebase/auth";

// Firebase configuration using environment variables
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Firebase Auth initialization
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

// Set custom parameters to always select account
provider.setCustomParameters({
  prompt: "select_account",
});

// Function to sign in with Google using a popup
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);

// Function to get the current user's token
export const getCurrentUserToken = () => {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        user
          .getIdToken()
          .then((token) => resolve(token))
          .catch((error) => reject(error));
      } else {
        resolve(null);
      }
    });
  });
};

// Function to check if a user is authenticated
export const isUserAuthenticated = () => {
  return new Promise((resolve) => {
    onAuthStateChanged(auth, (user) => {
      resolve(!!user);
    });
  });
};

// Export Firebase app and auth for external use
export { app, auth };
