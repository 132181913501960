import React from "react";
import { StyledMainButton } from "./MainButton.styled";

const MainButton = (props) => {
  const { text, imgSrc, className = "", handleClick } = props;

  return (
    <StyledMainButton className={className} onClick={handleClick}>
      {imgSrc && <img src={imgSrc} alt={text} />}
      {text}
    </StyledMainButton>
  );
};

export default MainButton;
