import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import MeraLogoWhite from "../../assets/images/meraLogoWhite.png";
import GoogleIcon from "../../assets/images/icons/googleIcon.png";
import MainButton from "../../components/Buttons/MainButton";
import SmallFooter from "../../components/SmallFooter/SmallFooter";
import MobileScreen from "../../components/MobileScreen/MobileScreen";
import { StyledGetStarted } from "./GetStarted.styled";

const GetStarted = () => {
  const isMobile = useMediaQuery({ maxWidth: 600 });

  if (isMobile) {
    return <MobileScreen />;
  }

  return (
    <StyledGetStarted>
      <Link to="/">
        <img className="meraLogo" src={MeraLogoWhite} alt="Mera Labs" />
      </Link>
      <div className="getStartedMain">
        <div className="getStartedInfo">
          <h2>Meet Mera</h2>
          <p>The easiest way to bring AI into your workflow.</p>
          <MainButton text="Continue with Google" imgSrc={GoogleIcon} />
        </div>
        <SmallFooter />
      </div>
    </StyledGetStarted>
  );
};

export default GetStarted;
