import React from "react";
import { StyledMainButton } from "./MainButton.styled";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { signInWithGooglePopup } from "../../utils/firebase.utils";
import {signUpUser} from "../Auth/ApiActions";

const MainButton = (props) => {
  const { text, imgSrc, className = "" } = props;
  const navigate = useNavigate();
  const auth = getAuth();

  const handleClick = async () => {
    try {
      // Check if user is already authenticated
      const currentUser = auth.currentUser;

      if (currentUser) {
        console.log("User already signed in:", currentUser);
        navigate("/welcome");
        return;
      }

      // If not signed in, trigger Google sign-in
      const response = await signInWithGooglePopup();
      console.log("User Info:", response.user);

      let userName = response._tokenResponse.firstName + " " + response._tokenResponse.lastName;
      console.log("User Info:", userName);
      signUpUser(response.user.email, userName);

      // Redirect to Welcome page upon successful sign-in
      navigate("/welcome");
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  return (
    <StyledMainButton className={className} onClick={handleClick}>
      {imgSrc && <img src={imgSrc} alt={text} />}
      {text}
    </StyledMainButton>
  );
};

export default MainButton;
