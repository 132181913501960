import React, { useState } from "react";
import icon1 from "../../../assets/images/icons/chatgpt.png";
import icon2 from "../../../assets/images/icons/gemini.png";
import icon3 from "../../../assets/images/icons/merlin.png";
import icon4 from "../../../assets/images/icons/speechify.png";
import icon5 from "../../../assets/images/icons/perplexity.png";
import icon6 from "../../../assets/images/icons/grammarly.png";
import tick from "../../../assets/images/icons/tick.png";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

const Pricing = () => {
  const [isYearly, setIsYearly] = useState(false);

  return (
    <>
      <Navbar />
      <div className="wrapper">
        <section className="flex flex-col app-container py-4 px-2 lg:px-12 max-w-full xl:max-w-7xl mx-auto pt-20">
          <h1 className="font-bold text-4xl lg:text-7xl text-neutral-800 text-center mt-12 lg:leading-16">
            <span className="bg-gradient-to-br from-violet-500 via-violet-400 to-pink-400 inline-block text-transparent bg-clip-text">
              All-In-One
            </span>{" "}
            AI Support For
            <br />
            <span className="bg-gradient-to-br from-violet-500 via-violet-400 to-pink-400 inline-block text-transparent bg-clip-text">
              Your Browser
            </span>
          </h1>
          {/* Toggle Buttons */}
          <div className="flex justify-center mt-8">
            <div className="bg-white p-1 flex gap-2 rounded-lg border border-gray-300">
              <button
                onClick={() => setIsYearly(false)}
                className={`px-4 py-2 font-semibold rounded-lg ${
                  !isYearly
                    ? "bg-gradient-to-br from-violet-700 to-violet-500 text-white"
                    : "bg-white text-gray-400"
                }`}
              >
                Monthly
              </button>
              <button
                onClick={() => setIsYearly(true)}
                className={`px-4 py-2 font-semibold rounded-lg ${
                  isYearly
                    ? "bg-gradient-to-br from-violet-700 to-violet-500 text-white"
                    : "bg-white text-gray-400 "
                }`}
              >
                Yearly ( 10% off)
              </button>
            </div>
          </div>
          {/* Pricing Section */}
          <div className="mt-8">
            {isYearly ? (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center">
                {/* Multiple Tools Pricing*/}
                <span className="multiple-tools bg-white px-4 py-6 rounded-lg border border-orange-500 h-fit">
                  <h3 className="text-lg text-gray-700 mb-4">
                    Using Multiple Tools...
                  </h3>
                  <span className="flex gap-2 items-end mb-4">
                    {" "}
                    <h2 className="text-4xl text-gray-700">$150</h2>
                    <p className="text-gray-600">/Monthly</p>
                  </span>
                  <hr className="mb-3" />
                  <span className="flex flex-col gap-2">
                    <span className="flex flex-col gap-4">
                      <span className="flex gap-2 items-center">
                        <img src={icon1} alt="" className="h-5" />
                        <p className="text-gray-600">ChatGPT $20/m</p>
                      </span>
                      <span className="flex gap-2 items-center">
                        <img src={icon2} alt="" className="h-5" />
                        <p className="text-gray-600">Gemini Advanced $20/m</p>
                      </span>
                      <span className="flex gap-2 items-center">
                        <img src={icon3} alt="" className="h-5" />
                        <p className="text-gray-600">Merlin $29/m</p>
                      </span>
                      <span className="flex gap-2 items-center">
                        <img src={icon4} alt="" className="h-5" />
                        <p className="text-gray-600">Speechify $29/m</p>
                      </span>
                      <span className="flex gap-2 items-center">
                        <img src={icon5} alt="" className="h-5" />
                        <p className="text-gray-600">Perplexity $20/m</p>
                      </span>
                      <span className="flex gap-2 items-center">
                        <img src={icon6} alt="" className="h-5" />
                        <p className="text-gray-600">Grammarly $30/m</p>
                      </span>
                    </span>
                  </span>
                </span>
                {/* Mera Pro Pricing */}
                <span className="mera-pro bg-white px-4 py-6 rounded-lg border border-violet-500 shadow-sm shadow-violet-100 h-fit lg:hover:shadow-lg lg:hover:shadow-violet-200 lg:hover:scale-105 transition-all ease-linear">
                  <h3 className="text-lg text-gray-700 mb-4">
                    Mera Pro (Unlimited)
                  </h3>
                  <span className="flex gap-2 items-end mb-4">
                    {" "}
                    <h2 className="text-4xl text-gray-700">$324</h2>
                    <p className="text-gray-600">/Annualy</p>
                  </span>
                  <h4 className="text-gray-800 underline mb-2">
                    $0 Payment Today
                  </h4>
                  <a
                    href="https://stripe.com/payments/payment-links"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="px-4 py-2 font-semibold rounded-lg bg-gradient-to-br from-violet-700 to-violet-500 text-white transform hover:scale-105 transition-all ease-in mb-4">
                      Get Started
                    </button>
                  </a>
                  <hr className="mb-3" />
                  <h4 className="text-gray-800 my-4 text-lg">
                    Unlock your browser with ...
                  </h4>
                  <span className="flex flex-col gap-4">
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">
                        In-Browser Voice Assistant
                      </p>
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">Voice-to-Text Assistant</p>
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">
                        Autonomous Email Management
                      </p>
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">
                        Smart Emailing & Scheduling
                      </p>
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">
                        Summaries & Chat with Webpages{" "}
                      </p>
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">Personal CRM</p>
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">Highlight Quick Actions</p>
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">In-Browser Side Panel</p>
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">
                        Early Access to New Features
                      </p>
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">Priority Customer Support</p>
                    </span>
                  </span>
                  <p className="text-xs mt-5 text-gray-400">
                    *To ensure a enjoyable experience for everyone, please use
                    Mera responsibly. Exploitative or extreme usage, may lead to
                    account limitations or closure.
                  </p>
                </span>
                {/* Mera Premium Pricing */}
                <span className="premium bg-white px-4 py-4 rounded-lg border border-violet-500 h-fit">
                  <h3 className="text-lg text-gray-700 mb-4">Mera Premium</h3>

                  <a href="mailto:peacemaker@meralabs.io" rel="noreferrer">
                    <h4 className="text-violet-600 underline mb-4">
                      Contact Support Team
                    </h4>
                  </a>

                  <hr className="mb-3" />
                  <h4 className="text-gray-800 mb-4 text-lg">
                    Everything in Pro Plus...
                  </h4>
                  <span className="flex flex-col gap-4">
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">
                        Team-Wide Voice Automation
                      </p>
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">
                        Centralized CRM & Task Management
                      </p>
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">Advanced Integrations</p>
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">
                        Usage Analytics & Insights
                      </p>
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">
                        Dedicated Support Representative{" "}
                      </p>
                    </span>
                  </span>
                </span>
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center">
                <span className="multiple-tools bg-white px-4 py-6 rounded-lg border border-orange-500 h-fit">
                  <h3 className="text-lg text-gray-700 mb-4">
                    Using Multiple Tools...
                  </h3>
                  <span className="flex gap-2 items-end mb-4">
                    {" "}
                    <h2 className="text-4xl text-gray-700">$150</h2>
                    <p className="text-gray-600">/Monthly</p>
                  </span>
                  <hr className="mb-3" />
                  <span className="flex flex-col gap-2">
                    <span className="flex flex-col gap-4">
                      <span className="flex gap-2 items-center">
                        <img src={icon1} alt="" className="h-5" />
                        <p className="text-gray-600">ChatGPT $20/m</p>
                      </span>
                      <span className="flex gap-2 items-center">
                        <img src={icon2} alt="" className="h-5" />
                        <p className="text-gray-600">Gemini Advanced $20/m</p>
                      </span>
                      <span className="flex gap-2 items-center">
                        <img src={icon3} alt="" className="h-5" />
                        <p className="text-gray-600">Merlin $29/m</p>
                      </span>
                      <span className="flex gap-2 items-center">
                        <img src={icon4} alt="" className="h-5" />
                        <p className="text-gray-600">Speechify $29/m</p>
                      </span>
                      <span className="flex gap-2 items-center">
                        <img src={icon5} alt="" className="h-5" />
                        <p className="text-gray-600">Perplexity $20/m</p>
                      </span>
                      <span className="flex gap-2 items-center">
                        <img src={icon6} alt="" className="h-5" />
                        <p className="text-gray-600">Grammarly $30/m</p>
                      </span>
                    </span>
                  </span>
                </span>
                <span className="mera-pro bg-white px-4 py-6 rounded-lg border border-violet-500 shadow-sm shadow-violet-100 h-fit lg:hover:shadow-lg lg:hover:shadow-violet-200 lg:hover:scale-105 transition-all ease-linear">
                  <h3 className="text-lg text-gray-700 mb-4">
                    Mera Pro (Unlimited)
                  </h3>
                  <span className="flex gap-2 items-end mb-4">
                    {" "}
                    <h2 className="text-4xl text-gray-700">$30</h2>
                    <p className="text-gray-600">/Monthly</p>
                  </span>
                  <h4 className="text-gray-800 underline mb-2">
                    $0 Payment Today
                  </h4>
                  <a href="https://stripe.com" target="_blank" rel="noreferrer">
                    <button className="px-4 py-2 font-semibold rounded-lg bg-gradient-to-br from-violet-700 to-violet-500 text-white transform hover:scale-105 transition-all ease-in mb-4">
                      Start 2-Day Free Trial
                    </button>
                  </a>
                  <hr className="mb-3" />
                  <h4 className="text-gray-800 my-4 text-lg">
                    Unlock your browser with ...
                  </h4>
                  <span className="flex flex-col gap-4">
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">
                        In-Browser Voice Assistant
                      </p>
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">Voice-to-Text Assistant</p>
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">
                        Autonomous Email Management
                      </p>
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">
                        Smart Emailing & Scheduling
                      </p>
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">
                        Summaries & Chat with Webpages{" "}
                      </p>
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">Personal CRM</p>
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">Highlight Quick Actions</p>
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">In-Browser Side Panel</p>
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">
                        Early Access to New Features
                      </p>
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">Priority Customer Support</p>
                    </span>
                  </span>
                  <p className="text-xs mt-5 text-gray-400">
                    *To ensure a enjoyable experience for everyone, please use
                    Mera responsibly. Exploitative or extreme usage, may lead to
                    account limitations or closure.
                  </p>
                </span>
                <span className="premium bg-white px-4 py-4 rounded-lg border border-violet-500 h-fit">
                  <h3 className="text-lg text-gray-700 mb-4">Mera Premium</h3>
                  <a href="mailto:peacemaker@meralabs.io" rel="noreferrer">
                    <h4 className="text-violet-600 underline mb-4">
                      Contact Support Team
                    </h4>
                  </a>
                  <hr className="mb-3" />
                  <h4 className="text-gray-800 mb-4 text-lg">
                    Everything in Pro Plus...
                  </h4>
                  <span className="flex flex-col gap-4">
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">
                        Team-Wide Voice Automation
                      </p>
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">
                        Centralized CRM & Task Management
                      </p>
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">Advanced Integrations</p>
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">
                        Usage Analytics & Insights
                      </p>
                    </span>
                    <span className="flex gap-2 items-center">
                      <img src={tick} alt="" className="h-5" />
                      <p className="text-gray-600">
                        Dedicated Support Representative{" "}
                      </p>
                    </span>
                  </span>
                </span>
              </div>
            )}
          </div>
          <div className="faq my-8 lg:my-12 flex flex-col gap-6 items-center">
            <h3 className="text-2xl">Frequently Asked Questions</h3>
            <div className="flex gap-4 flex-col lg:flex-row ">
              <span className="bg-purple-100 rounded-xl p-5 flex flex-col gap-2">
                <span className="text-lg">
                  Who stores my credit card information
                </span>
                <p className="text-gray-500 text-sm">
                  All payments and security are handled by Stripe. We never see
                  or store your credit card details. Stripe is PCI-certified,
                  and all card numbers are encrypted at rest with 256-bit
                  encryption. You can read more about their security practices
                  at{" "}
                  <a
                    href="https://stripe.com"
                    className="underline hover:text-violet-500"
                    target="_blank"
                    rel="noreferrer"
                  >
                    stripe.com
                  </a>{" "}
                  .
                </p>
              </span>
              <span className="bg-purple-100 p-5 rounded-xl flex flex-col gap-2">
                <span className="text-lg">What is your refund policy?</span>
                <p className="text-gray-500 text-sm">
                  We do not offer refunds, but we provide a 2-day free trial so
                  you can explore all features before committing to a paid plan.
                  You can cancel your subscription at any time directly from
                  your account settings.
                  <a
                    href="https://stripe.com"
                    className="underline hover:text-violet-500"
                    target="_blank"
                    rel="noreferrer"
                  >
                    stripe.com
                  </a>{" "}
                  .
                </p>
              </span>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Pricing;
