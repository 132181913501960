export const signUpUser = (userEmail, userName) => {
  try {
    const url = process.env.REACT_APP_MERA_BASE_URL + "/signup_user";
    fetch(url, {
      mode: "no-cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ email: userEmail, name: userName }),
    })
      .then((response) => console.log(response))
      .catch((error) => console.error(error));
  } catch (error) {
    console.log(error.data);
  }
};
