import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const TermsOfService = () => {
  return (
    <>
      <Navbar />
      <div className="p-6 space-y-6">
        <h1 className="text-2xl font-bold mb-4">Terms of Service</h1>
        <p className="text-sm text-gray-500">Last Updated: October 7, 2024</p>
        <p>
          Welcome to Mera Labs Technologies! These Terms of Service ("Terms")
          govern your use of the Mera Chrome extension and related services
          ("Services"). By installing the Mera extension and using our Services,
          you agree to be bound by these Terms.
        </p>
        <h2 className="text-xl font-semibold mt-6">Acceptance of Terms</h2>
        <p>
          By accessing or using Mera, you confirm that you are of legal age and
          have the authority to enter into this agreement. If you are using Mera
          on behalf of an organization, you represent that you have the
          authority to bind that organization to these Terms.
        </p>
        <h2 className="text-xl font-semibold mt-6">Description of Services</h2>
        <p>
          Mera is a voice-activated, AI-driven browser assistant designed to
          enhance your experience by handling tasks such as email management,
          scheduling, web searches, reading articles, and providing real-time
          insights. This seamless, hands-free browsing assistant boosts
          productivity and accessibility through in-browser interactions.
        </p>
        <h2 className="text-xl font-semibold mt-6">
          License and Intellectual Property
        </h2>
        <p>
          Mera Labs Technologies grants you a limited, non-exclusive,
          non-transferable license to use the Services for personal,
          non-commercial use. All rights, title, and interest in the Services,
          including updates and new features, remain with Mera Labs
          Technologies. Unauthorized reproduction, modification, or distribution
          is prohibited.
        </p>
        <h2 className="text-xl font-semibold mt-6">User Responsibilities</h2>
        <ul className="list-disc pl-6 space-y-2">
          <li>
            Use Mera only for lawful purposes and according to these Terms.
          </li>
          <li>
            Maintain the confidentiality of account credentials and inform us
            immediately of unauthorized account access.
          </li>
          <li>
            You agree not to use Mera to:
            <ul className="list-disc pl-6">
              <li>Violate any applicable law.</li>
              <li>Infringe upon others' rights.</li>
              <li>Transmit malicious code.</li>
              <li>Interfere with Mera's operation or networks.</li>
              <li>
                Attempt unauthorized access to Mera or other users' accounts.
              </li>
            </ul>
          </li>
        </ul>
        <h2 className="text-xl font-semibold mt-6">Privacy and Data Usage</h2>
        <p>
          Mera Labs collects and uses data according to our Privacy Policy,
          available at meralabs.io. This includes data on browsing interactions,
          search history, and usage patterns to enhance functionality and user
          experience.
        </p>
        <h2 className="text-xl font-semibold mt-6">Disclaimer of Warranties</h2>
        <ul className="list-disc pl-6 space-y-2">
          <li>
            The Services are provided "as is" and "as available," with no
            warranties regarding accuracy, reliability, or availability.
          </li>
          <li>
            Mera Labs does not warrant uninterrupted or error-free operation or
            that any defects will be corrected.
          </li>
        </ul>
        <h2 className="text-xl font-semibold mt-6">Limitation of Liability</h2>
        <ul className="list-disc pl-6 space-y-2">
          <li>
            To the maximum extent permitted by law, Mera Labs and its
            affiliates, officers, and employees are not liable for any direct,
            indirect, incidental, or consequential damages resulting from your
            use of the Services.
          </li>
          <li>
            Mera Labs' total liability shall not exceed the fees you paid, if
            any, for the Services in the six months preceding the claim.
          </li>
        </ul>
        <h2 className="text-xl font-semibold mt-6">
          Prohibited Activities and Account Suspension
        </h2>
        <p>Prohibited activities leading to suspension include:</p>
        <ul className="list-disc pl-6 space-y-2">
          <li>Sharing accounts with multiple individuals or bots/AI.</li>
          <li>Inputting or soliciting inappropriate or explicit content.</li>
          <li>Inciting violence or harmful behavior via AI responses.</li>
          <li>Running unauthorized scripts on Mera to exploit AI.</li>
          <li>Exceeding standard usage rates by over 2.5x monthly fees.</li>
        </ul>
        <p>
          Mera reserves the right to suspend accounts temporarily or permanently
          if excessive usage adversely affects service quality or
          sustainability.
        </p>
        <h2 className="text-xl font-semibold mt-6">
          Modifications to Terms and Service
        </h2>
        <p>
          Mera Labs may modify these Terms or the Services. You are encouraged
          to review the Terms regularly. Changes are effective upon posting.
          Mera Labs also reserves the right to modify, suspend, or discontinue
          any feature or functionality.
        </p>
        <h2 className="text-xl font-semibold mt-6">Termination</h2>
        <p>
          Mera Labs reserves the right to terminate your access to the Services
          for violation of these Terms or if continued usage is unsustainable.
        </p>
        <h2 className="text-xl font-semibold mt-6">Governing Law</h2>
        <p>
          These Terms are governed by the laws of the State of Delaware, USA.
          Any legal action or proceeding shall be brought exclusively in the
          state or federal courts located in Delaware.
        </p>
        <h2 className="text-xl font-semibold mt-6">Severability</h2>
        <p>
          If any provision is found invalid, the remaining provisions shall
          remain in effect.
        </p>
        <h2 className="text-xl font-semibold mt-6">Entire Agreement</h2>
        <p>
          These Terms constitute the entire agreement between you and Mera Labs
          regarding the Services, superseding all prior agreements.
        </p>
        <h2 className="text-xl font-semibold mt-6">Contact Information</h2>
        <p>
          For questions or concerns, please contact us at{" "}
          <a href="mailto:peacemaker@meralabs.io" className="text-blue-500">
            peacemaker@meralabs.io
          </a>
          .
        </p>
        <p className="font-semibold">
          Thank you for using Mera Labs Technologies!
        </p>
      </div>
      <Footer />
    </>
  );
};

export default TermsOfService;
