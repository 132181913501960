import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Pricing from "./Pages/OLD/Pricing/Pricing.jsx";
import Home from "./Pages/Home/Home.jsx";
import Terms from "./Pages/TermsOfService/TermsOfService.jsx";
import Privacy from "./Pages/PrivacyPolicy/PrivacyPolicy.jsx";
import GetStarted from "./Pages/GetStarted/GetStarted.jsx";
import Welcome from "./Pages/Welcome/Welcome.jsx";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.css";
import ProtectedRoute from "./components/Protected/ProtectedRoute.jsx";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/termsofservice" element={<Terms />} />
        <Route path="/privacypolicy" element={<Privacy />} />
        <Route path="/getstarted" element={<GetStarted />} />
        {/* Protected Route */}
        <Route
          path="/welcome"
          element={
            <ProtectedRoute>
              <Welcome />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
