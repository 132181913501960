import styled from "styled-components";

export const StyledSmallFooter = styled.div`
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  p {
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.9;
    letter-spacing: 0.12px;
    a {
      text-decoration: none;
      color: #9364f5;
    }
  }
  span {
    margin: 16px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.12px;
    a {
      text-decoration: underline;
    }
  }
  /* Media Queries */
  @media (max-width: 1460px) {
    bottom: 50px;
    width: 100%;
    max-width: 360px;
    p {
      br {
        display: none;
      }
    }
  }
`;
