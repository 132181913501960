import styled from "styled-components";

export const StyledAiCard = styled.div`
  padding: 32px;
  width: 100%;
  background: #211f23;
  border: 3px solid #b999ff;
  border-radius: 20px;
  .aiCardInfo {
    img {
      width: 32px;
      height: 32px;
      display: block;
      object-fit: cover;
    }
    h4 {
      margin: 20px 0 0 0;
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      line-height: 1;
    }
    p {
      margin: 16px 0;
      color: #969696;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
    }
  }
  .aiCardVideo {
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    iframe {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
  }
`;
