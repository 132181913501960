import React from "react";
import MainButton from "../Buttons/Button";
import MouseIcon from "../../assets/images/icons/mouseIcon.png";
import SmallFooter from "../../components/SmallFooter/SmallFooter";
import { StyledMobileScreen } from "./MobileScreen.styled";

const MobileScreen = () => {
  return (
    <StyledMobileScreen>
      <MainButton text="Page Only Available on Desktop" imgSrc={MouseIcon} />
      <SmallFooter />
    </StyledMobileScreen>
  );
};

export default MobileScreen;
