import React from "react";
import { Link } from "react-router-dom";
import MeraLogoWhite from "../../assets/images/meraLogoWhite.png";
import MeraLogoBlack from "../../assets/images/meralogo.png";
import SlackIconBlack from "../../assets/images/icons/slack.png";
import SlackIconWhite from "../../assets/images/icons/slackIconWhite.png";
import LinkedinIconBlack from "../../assets/images/icons/linkedin.png";
import LinkedinIconWhite from "../../assets/images/icons/linkedinkIconWhite.png";
import TwitterIconBlack from "../../assets/images/icons/twitterIconBlack.png";
import TwitterIconWhite from "../../assets/images/icons/twitterIconWhite.png";
import Container from "../../components/Container/Container";
import { StyledFooter } from "./Footer.styled";

const Footer = (props) => {
  const { lightTheme = true, className = "" } = props;

  // https://x.com/MeraLabsHQ

  return (
    <StyledFooter className={`${className} ${lightTheme && "lightMode"}`}>
      <Container className="footerContainer">
        <div className="footerLeft">
          <Link to="/">
            <img
              src={lightTheme ? MeraLogoBlack : MeraLogoWhite}
              alt="Mera Labs"
            />
          </Link>
          <p>© 2024 Mera Labs Technologies Inc.</p>
        </div>
        <div className="footerRight">
          <div className="footerSocial">
            <a
              href="https://join.slack.com/t/meralabs/shared_invite/zt-2uehy3p1t-VtZZHDJGHWZASAiEa4vuTg"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={lightTheme ? SlackIconBlack : SlackIconWhite}
                alt="Slack"
              />
            </a>
            <a
              href="https://linkedin.com/company/mera-labs"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={lightTheme ? LinkedinIconBlack : LinkedinIconWhite}
                alt="Linkedin"
              />
            </a>
            <a href="https://x.com/MeraLabsHQ" target="_blank" rel="noreferrer">
              <img
                src={lightTheme ? TwitterIconBlack : TwitterIconWhite}
                alt="Twitter"
              />
            </a>
          </div>
          <div className="footerLinks">
            <Link to="/termsofservice" target="_blank">
              Terms of Service
            </Link>
            <div className="divider" />
            <Link to="/privacypolicy" target="_blank">
              Privacy Policy
            </Link>
          </div>
        </div>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
