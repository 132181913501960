import React from "react";
import { Link } from "react-router-dom";
import MeraLogoWhite from "../../assets/images/meraLogoWhite.png";
import MeraLogoBlack from "../../assets/images/meralogo.png";
import MouseIconSmall from "../../assets/images/icons/mouseIconSmall.png";
import Container from "../../components/Container/Container";
import Button from "../../components/Buttons/Button";
import { StyledNavbar } from "./Navbar.styled";

const Navbar = (props) => {
  const { lightTheme = true, className = "" } = props;

  return (
    <StyledNavbar className={`${className} ${lightTheme && "lightMode"}`}>
      <Container className="navContainer">
        <Link to="/">
          <img
            src={lightTheme ? MeraLogoBlack : MeraLogoWhite}
            alt="Mera Labs"
          />
        </Link>
        {/* <div className="navLinks">
          <a
            href="https://peacemakers-organization.gitbook.io/mera-labs/"
            target="_blank"
            rel="noreferrer"
          >
            Docs
          </a>
        </div> */}
        <Button
          text="Join Our Community"
          imgSrc={MouseIconSmall}
          handleClick={() =>
            window.open(
              "https://join.slack.com/t/meralabs/shared_invite/zt-2uehy3p1t-VtZZHDJGHWZASAiEa4vuTg",
              "_blank"
            )
          }
        />
      </Container>
    </StyledNavbar>
  );
};

export default Navbar;
