import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  @media (max-width: 1360px) {
    max-width: 94%;
  }
`;
