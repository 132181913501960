import styled from "styled-components";

export const StyledMobileScreen = styled.section`
  padding: 0 40px;
  width: 100%;
  min-height: 100vh;
  background: #1a1121;
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  button {
    width: 100%;
    max-width: 440px;
    font-size: 15px;
    padding: 3px 0 0 0;
    cursor: not-allowed;
  }
`;
