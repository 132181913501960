import styled from "styled-components";

export const StyledFooter = styled.footer`
  padding: 40px 0;
  width: 100%;
  background: #1a1121;
  display: flex;
  align-items: center;
  &.lightMode {
    background: #f5f5f5;
    .footerContainer {
      .footerLeft {
        p {
          color: #737373;
        }
      }
      .footerRight {
        .footerLinks {
          a {
            color: #525252;
          }
          .divider {
            background: #525252;
          }
        }
      }
    }
  }
  .footerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .footerLeft,
    .footerRight {
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .footerLeft {
      a {
        text-decoration: none;
        img {
          width: 108px;
          height: auto;
          display: block;
          object-fit: cover;
        }
      }
      p {
        color: rgba(255, 255, 255, 0.92);
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
      }
    }
    .footerRight {
      .footerSocial {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 15px;
        a {
          text-decoration: none;
          img {
            width: 22px;
            height: 22px;
            display: block;
            object-fit: cover;
          }
        }
      }
      .footerLinks {
        display: flex;
        align-items: center;
        gap: 10px;
        a {
          text-decoration: none;
          color: rgba(255, 255, 255, 0.92);
          font-size: 14px;
          font-weight: 500;
          line-height: 1;
        }
        .divider {
          width: 1px;
          height: 100%;
          background: #272727;
        }
      }
    }
  }
  // Media Queries
  @media (max-width: 600px) {
    .footerContainer {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 40px;
      .footerLeft,
      .footerRight {
        height: auto;
        align-items: center;
        gap: 14px;
      }
    }
  }
`;
