import styled from "styled-components";

export const StyledMainButton = styled.button`
  border: none;
  outline: none;
  border-radius: 12px;
  background: linear-gradient(141deg, #9364f5 0%, #581ada 100%);
  width: 440px;
  height: 56px;
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  img {
    width: 24px;
    height: 24px;
    display: block;
    object-fit: cover;
  }
`;
