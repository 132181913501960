import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import MobileScreen from "../../components/MobileScreen/MobileScreen";
import Button from "../../components/Buttons/Button";
import { StyledWelcome } from "./Welcome.styled";

const Welcome = () => {
  const [counter, setCounter] = useState(7);
  const isMobile = useMediaQuery({ maxWidth: 600 });

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  if (isMobile) {
    return <MobileScreen />;
  }

  return (
    <StyledWelcome>
      <div className="videoContainer">
        <iframe
          src="https://www.youtube.com/embed/6LOtqprIeGQ"
          frameborder="0"
          allowfullscreen
          title="Get Started"
        ></iframe>
      </div>
      <Button
        className={counter > 0 ? "inactive" : ""}
        text="Get Started"
        handleClick={() => window.location.href = "https://www.google.com/"}
      />

      <p>Watch to Get Started</p>
    </StyledWelcome>
  );
};

export default Welcome;
